















































import { Component, Vue } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { PackageService, FamilyService } from "src/services";
import EnumUtils from "src/utils/enum-utils";
import { RELATION } from "src/enums";

@Component
export default class PackageList extends Vue
{
    /**
     * 当前选中用户
     * @protected
     * @returns string
     */
    protected selectUser: any = {};

    /**
     * 选择人员
     * @protected
     * @returns boolean
     */
    protected showUserPopup: boolean = false;

    /**
     * 体检数据
     * @protected
     * @returns Array<any>
     */
    protected medicalList: Array<any> = [];

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 当前位置
     * @private
     * @returns any
     */
    protected get location(): any
    {
        return this.$store.getters.location || {};
    }

    /**
     * 获取类型
     * @private
     * @returns any
     */
    protected get type(): any
    {
        return this.$route.query && this.$route.query.type;
    }

    /**
     * 获取服务id
     * @private
     * @returns any
     */
    protected get serviceItemId(): any
    {
        return this.$route.query && this.$route.query.serviceItemId;
    }

    /**
     * 获取与本人关系
     * @private
     * @returns string
     */
    protected getRelationship(value: number): string
    {
        return EnumUtils.getEntry(value, RELATION) && EnumUtils.getEntry(value, RELATION).description;
    }

    /**
     * 跳转套餐详情
     * @private
     * @returns void
     */
    protected onPackageDetail(item: any): void
    {
        localStorage.setItem("selectUser", JSON.stringify(this.selectUser));
        this.$router.push({name: "package-detail", query:{id: item.id, hospitalId: item.hospId,serviceItemId:this.serviceItemId}});
    }

    /**
     * 返回
     * @private
     * @returns void
     */
    private goBack(): void
    {
        this.$router.push({name: "home"});
    }

    /**
     * 选择用户
     * @private
     * @returns void
     */
    protected onShowUserModal(): void
    {
        // 权益跳转过来的直接编辑当前用户否则选择用户
        if(this.serviceItemId)
        {
            this.$router.push({name: "family-operation",query: {userId: this.userInfo.userId}});
        }
        else
        {
            this.showUserPopup = true;
        }
    }

    /**
     * 确认切换用户
     * @private
     * @returns void
     */
    protected onConfirmUser(data: any): void
    {
        if(data.id !== this.selectUser.id)
        {
            this.selectUser = Object.assign({},data);
            this.getMedicalpackage(data.id);
            localStorage.setItem("selectUserId", data.id)
        }
    }

    /**
     * 获取体检套餐
     * @protected
     * @returns string
     */
    protected async getMedicalpackage(memberId: string): Promise<void>
    {
        try
        {
            let data =
            {
                serviceItemId: this.serviceItemId,
                memberId: memberId,
                cityName: this.location.city,
                longitude: this.location.lng,
                latitude: this.location.lat,
                packageType: this.type
            }
            let {content: result} = await PackageService.instance.getMedicalpackage(data);

            if(result.data)
            {
                this.medicalList = result.data;
            }
            
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 组件创建钩子
     * @protected
     * @returns void
     */
    protected created()
    {
        // 获取体检套餐
        this.getMedicalpackage(this.userInfo.userId);
        this.selectUser =
        {
            id: this.userInfo.userId,
            memberType: 1,
            familyType: 1,
            memberName: this.userInfo.userName
        };
    }
    
}
