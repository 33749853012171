




























































































































import { Component, Vue } from "vue-property-decorator";
import { PackageService, WechatService, OrganizationService } from "src/services";
import { Toast, Dialog } from "vant";
import { tools } from "src/utils";
import dayjs from "dayjs";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate" // for vue-router 2.2+
]);

@Component
export default class PackageDetail extends Vue
{

    /**
     * 选择日期的下标
     * @private
     * @returns any
     */
    private currentIndex: number = -1;

    /**
     * 选中日期
     * @private
     * @returns any
     */ 
    private selectDate: any = "";

    /**
     * 排期数据
     * @private
     * @returns any
     */
    private schedulingData: any = [];

    /**
     * 排期数据
     * @private
     * @returns any
     */
    private weekList: any = ["周日","周一", "周二", "周三","周四", "周五","周六"];

    /**
     * 固定套餐项目
     * @private
     * @returns any
     */
    private baseItemTypesOut: any = null;

    /**
     * 套餐信息
     * @private
     * @returns any
     */
    private packageOut: any = null;

    /**
     * 机构信息
     * @private
     * @returns any
     */
    private hospitalOut: any = null;

    /**
     * 搜索条件
     * @private
     * @returns any
     */
    private filter: any =
    {
        packageId: null, // 套餐id
        hospitalId: null, // 医院Id
        cityName: null, // 城市名称
        longitude: null, // 经度
        latitude: null // 纬度
    };

    /**
     * 固定项目打开id
     * @private
     * @returns any
     */
    private fixedCollapseActive: any = [];

    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private beforeRouteEnter(to, from, next): void
    {
        let appid = localStorage.getItem("appid");
        if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname)
        {
           location.assign(`/${appid}${to.fullPath}`);
        }
        else
        {
            next();
        }
    }

    /**
     * 医院/机构id
     * @private
     * @returns string
     */
    private get hospitalId(): string
    {
        return this.$route.query && (this.$route.query.hospitalId as string) || "";
    }
    
    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get selectUserId(): any
    {
        let selectUserStorage = localStorage.getItem("selectUser");
        
        if(selectUserStorage)
        {
            return JSON.parse(selectUserStorage).id || this.userInfo.userId;
        }

        return this.userInfo.userId;
    }

    /**
     * 套餐id
     * @private
     * @returns string
     */
    private get packageId(): string
    {
        return this.$route.query && (this.$route.query.id as string) || "";
    }

    /**
     * 当前位置
     * @private
     * @returns any
     */
    private get location(): any
    {
        return this.$store.getters.location || {};
    }

    /**
     * 获取服务id
     * @private
     * @returns any
     */
    protected get serviceItemId(): any
    {
        return this.$route.query && this.$route.query.serviceItemId;
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取固定套餐价格
     * @private
     * @returns number
     */
    private get fixedPrice(): string
    {
        let sumPrice = 0;
        if(this.baseItemTypesOut && this.baseItemTypesOut.length>0)
        {
            this.baseItemTypesOut.forEach((item)=>
            {
                item.items.forEach(d=>
                {
                    sumPrice+=d.itemPrice;
                })
            })
        }
        return sumPrice.toFixed(2);
    }

    /**
     * 切换机构
     * @private
     * @returns void
     */
    private onChangeOrg(): void
    {
        this.$router.replace({name: "orgList", query:{packageId: this.packageId, serviceItemId: this.serviceItemId}});
    }

    /**
     * 返回
     * @private
     * @returns void
     */
    private goBack(): void
    {
        this.$router.push({name: "package-list", query:{type: this.packageOut.packageType, serviceItemId:this.serviceItemId}});
    }

    /**
     * 下一步
     * @private
     * @returns void
     */
    private onNext(): void
    {
        this.$router.push({name: "optional-package", query:{id: this.packageId, date: this.selectDate,serviceItemId:this.serviceItemId, hospitalId: this.hospitalId || this.hospitalOut.hospitalId}});
    }

    /**
     * 获取某个机构排期
     * @private
     * @returns void
     */
    private async getMedicalOrgDateInfo(): Promise<void>
    {
        try
        {
            let {content: result} =  await OrganizationService.instance.getMedicalOrgDateInfo({
                packageId: this.packageId,
                packageCode: this.packageOut.packageCode,
                hospitalId: this.hospitalOut.hospitalId,
                hospitalCode: this.hospitalOut.code
            });
            
            if(result.data)
            {
               let schedules = result.data.schedules || [];
                
                let schedulesObj = {};
                schedules.forEach(item=>
                {
                    schedulesObj[dayjs(item.startTime).format("YYYY-MM-DD")] = item.personCount;
                })
                this.handlerCalendar(schedulesObj)
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 跳转地图
     * @private
     * @returns void
     */
    private async openMap(hospitalOut: any): Promise<any>
    {
        if(hospitalOut)
        {
            let lat = hospitalOut.latitude;
            let lng =hospitalOut.longitude;;
            let address = hospitalOut.address;
            let orgName = hospitalOut.hospitalName;

            if(tools.isWechat())
            {
                let currentUrl = location.href;
                let {content: result} = await WechatService.instance.getWechatJSSDK(currentUrl);
                if(result.data)
                {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: result.data.appId, // 必填，公众号的唯一标识
                        timestamp: result.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
                        signature: result.data.signature, // 必填，签名
                        jsApiList: ["openLocation"] // 必填，需要使用的JS接口列表
                    });

                    wx.ready(function()
                    {
                        wx.openLocation({
                            latitude: parseFloat(lat), // 纬度，范围为-90~90，负数表示南纬
                            longitude: parseFloat(lng), // 经度，范围为-180~180，负数表示西经
                            scale: 14, // 缩放比例
                            name: orgName,
                            address: address, // 地图缩放级别,整形值,范围从1~28。默认为最大
                            infoUrl: ""
                        });
                    });
                }
            }
            else
            {
                // 腾讯地图
                // let href = `http://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};addr:${this.orgDetailInfo.org_name}`;
                // 高德
                let href = `http://uri.amap.com/marker?position=${lng},${lat}&name=${orgName}&coordinate=gaode&callnative=1`;
                window.open(href);
            }

        }

    }

    /**
     * 组件创建
     * @private
     * @returns void
     */
    private created(): void
    {
        if(this.hospitalId)
        {
            this.filter.hospitalId = this.hospitalId;
        }

        this.filter.packageId = this.packageId;
        this.filter.cityName = this.location && this.location.city;
        this.filter.longitude = this.location && this.location.lng;
        this.filter.latitude = this.location && this.location.lat;
        this.filter.memberId = +this.selectUserId;

        this.getPackageDetail(this.filter);
    }

    /**
     * 显示日历空间日期
     * @private
     * @returns void
     */
    private handlerCalendar(schedulesObj: any): void
    {
        let dateList = [];

        for(let i = 0; i<60; i++)
        {
            let current = dayjs().add(i, "day").format("YYYY-MM-DD");
            let num = 0
            if(schedulesObj[current]&&schedulesObj[current]>0)
            {
                num = schedulesObj[current];
            }

            let obj =
            {
                date: current,
                num: num,
                txt: dayjs(current).format("MM.DD"),
                week: this.weekList[dayjs(current).day()]
            };
            dateList.push(obj)
        }
        this.schedulingData = dateList;
    }

    /**
     * 获取套餐详情
     * @private
     * @returns void
     */
    private async getPackageDetail(data: any): Promise<void>
    {
        try
        {
            let {content: result} =  await PackageService.instance.getPackageInfo(data);

            if(result.data)
            {
                this.baseItemTypesOut = result.data.baseItemTypesOut;
                this.packageOut = result.data.packageOut;
                this.hospitalOut = result.data.hospitalOut;
                
                this.getMedicalOrgDateInfo();
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 显示提示
     * @private
     * @returns void
     */
    private showTip(remark: string): void
    {
        Dialog.alert({
            confirmButtonColor: "#00d5c1",
            messageAlign: "left",
            message: remark,
            confirmButtonText: "确定"
        });
    }

    /**
     * 选择日期
     * @private
     * @returns void
     */
    private onSelectDate(item: any, index: number): void
    {
        if(item.num>0)
        {
            this.currentIndex = index;
            this.selectDate= item.date
        }
        
    }

}
