







































































































import { Component, Vue } from "vue-property-decorator";
import { PackageService, WechatService, OrganizationService } from "src/services";
import { Toast, Dialog } from "vant";
import { tools } from "src/utils";
import dayjs from "dayjs";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate" // for vue-router 2.2+
]);

@Component
export default class OptionalPackageDetail extends Vue
{
    /**
     * 固定套餐项目
     * @private
     * @returns any
     */ 
    private showlookdetail: boolean = false;

    /**
     * 显示项目备注
     * @private
     * @returns boolean
     */ 
    private showTipModel: boolean = false;

    /**
     * 选中的加项
     * @private
     * @returns Array<string| number>
     */
    private selectOptionalItem: Array<string| number> = []

    /**
     * 固定套餐项目
     * @private
     * @returns any
     */
    private baseItemTypesOut: any = null;

    /**
     * 加项套餐项目
     * @private
     * @returns any
     */
    private optionalItemTypesOut: any = null;

    /**
     * 套餐信息
     * @private
     * @returns any
     */
    private packageOut: any = null;

    /**
     * 机构信息
     * @private
     * @returns any
     */
    private hospitalOut: any = null;

    /**
     * 搜索条件
     * @private
     * @returns any
     */
    private filter: any =
    {
        packageId: null, // 套餐id
        hospitalId: null, // 医院Id
        cityName: null, // 城市名称
        longitude: null, // 经度
        latitude: null // 纬度
    };

    /**
     * 自选项展开项目
     * @private
     * @returns any
     */
    private collapseActiveNames: any = [];

    /**
     * 医院/机构id
     * @private
     * @returns string
     */
    private get hospitalId(): string
    {
        return this.$route.query && (this.$route.query.hospitalId as string) || "";
    }

    /**
     * 需要支付价格
     * @private
     * @returns number
     */
    private get payPrice(): any
    {
        // 权益直接返回加项
        if(this.serviceItemId)
        {
            return this.optionalPrice;
        }
        else
        {
            return (+this.fixedPrice + (+this.optionalPrice)).toFixed(2);
        }
       
    }

    /**
     * 获取服务id
     * @private
     * @returns any
     */
    protected get serviceItemId(): any
    {
        return this.$route.query && this.$route.query.serviceItemId;
    }
    
    /**
     * 获取固定套餐价格
     * @private
     * @returns number
     */
    private get fixedPrice(): string
    {
        let sumPrice = 0;
        if(this.baseItemTypesOut && this.baseItemTypesOut.length>0)
        {
            this.baseItemTypesOut.forEach((item)=>
            {
                item.items.forEach(d=>
                {
                    sumPrice+=d.itemPrice;
                })
            })
        }
        return sumPrice.toFixed(2);
    }

    /**
     * 自选金额
     * @private
     * @returns strig
     */
    private get optionalPrice(): number
    {
        let selectItemPrice = [];
        if(this.optionalItemTypesOut && this.optionalItemTypesOut.length > 0)
        {
            this.optionalItemTypesOut.forEach(item => {

                item.items.forEach(data =>
                {
                    if(this.selectOptionalItem.indexOf(data.itemId) > -1)
                    {
                        selectItemPrice.push(data.itemPrice);
                    }

                });
            });
        }
      
        // 计算总金额
        let sum = selectItemPrice.reduce((prve: number,curr: number) =>
        {
            return prve + curr;
        }, 0);


        return (sum).toFixed(2) || 0.00;
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get selectUserId(): any
    {
        let selectUserStorage = localStorage.getItem("selectUser");
        
        if(selectUserStorage)
        {
            return JSON.parse(selectUserStorage).id || this.userInfo.userId;
        }

        return this.userInfo.userId;
    }

    /**
     * 套餐id
     * @private
     * @returns string
     */
    private get packageId(): string
    {
        return this.$route.query && (this.$route.query.id as string) || "";
    }

    /**
     * 选中日期
     * @private
     * @returns string
     */
    private get selectDate(): string
    {
        return this.$route.query && (this.$route.query.date as string) || "";
    }

    /**
     * 当前位置
     * @private
     * @returns any
     */
    private get location(): any
    {
        return this.$store.getters.location || {};
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }


    /**
     * 关闭提示
     * @private
     * @returns void
     */
    private onCloseTip(): void
    {
        this.showTipModel = false;
    }

    /**
     * 立即预约
     * @private
     * @returns void
     */
    private onSubscribe(): void
    {
        localStorage.setItem("selectOptionalItem", JSON.stringify(this.selectOptionalItem));
        this.$router.push({name: "submit-order", query:{packageId: this.packageId, date: this.selectDate,serviceItemId:this.serviceItemId, hospitalId: this.hospitalId || this.hospitalOut.hospitalId}});
    }

    /**
     * 组件创建
     * @private
     * @returns void
     */
    private created(): void
    {
        if(this.hospitalId)
        {
            this.filter.hospitalId = this.hospitalId;
        }

        this.filter.packageId = this.packageId;
        this.filter.cityName = this.location && this.location.city;
        this.filter.longitude = this.location && this.location.lng;
        this.filter.latitude = this.location && this.location.lat;
        this.filter.memberId = +this.selectUserId;

        this.getPackageDetail(this.filter);
    }

    /**
     * 获取套餐详情
     * @private
     * @returns void
     */
    private async getPackageDetail(data: any): Promise<void>
    {
        try
        {
            let {content: result} =  await PackageService.instance.getPackageInfo(data);

            if(result.data)
            {
                this.baseItemTypesOut = result.data.baseItemTypesOut;
                this.optionalItemTypesOut = result.data.optionalItemTypesOut;
                this.packageOut = result.data.packageOut;
                this.hospitalOut = result.data.hospitalOut;
                // 处理默认选择
                this.optionalItemTypesOut && this.optionalItemTypesOut.forEach((item, index)=>
                {
                    this.collapseActiveNames.push(index);
                    item.items.forEach(d=>
                    {
                        if(d.isOptionalSelected)
                        {
                            this.selectOptionalItem.push(d.itemId);
                        }
                    })
                })
           
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 显示提示
     * @private
     * @returns void
     */
    private showTip(remark: string): void
    {
        Dialog.alert({
            confirmButtonColor: "#00d5c1",
            messageAlign: "left",
            message: remark,
            confirmButtonText: "确定"
        });
    }

}
